import APP_CONSTANT from "../../../constants/index.constant"
import generalBulletPoint from "../../../assets/svg/general-bullet-point.svg";
import raybanBulletPoint from "../../../assets/svg/rayban-bullet-point.svg";
import oakleylBulletPoint from "../../../assets/svg/oakley-bullet-point.svg";
import { useState } from "react";

interface Props {
    text: string,
    i?: number,
    theme: string,
    handleActiveItem?: any,
    activeItem: number,
}
const BenefitItem = ({ text, i, theme, activeItem, handleActiveItem }: Props) => {

    const [showFullText, setShowFullText] = useState(false);

    const setBulletPointTheme = () => {
        switch (theme) {
            case APP_CONSTANT.GENERAL:
                return generalBulletPoint
            case APP_CONSTANT.RAYBAN:
                return raybanBulletPoint
            case APP_CONSTANT.OAKLEY:
                return oakleylBulletPoint
        }
    }
    return (
        <li className={"bullet-point" + (activeItem > -1 && activeItem !== i ? ' bullet-point-small' : '')} onClick={() => text.length > 60 ? handleActiveItem(showFullText ? -1 : i) : ''}>
            <img alt="Bullet point" src={setBulletPointTheme()} />
            {
                text.length > 60 && !showFullText
                    ? <span onClick={() => setShowFullText(!showFullText)} className={`bullet-point-${theme}`} dangerouslySetInnerHTML={{ __html: text.substring(0, 50) + "... <small><b>Read more</b></small>" }}></span>
                    : <span onClick={() => setShowFullText(!showFullText)} className={`bullet-point-${theme}`} dangerouslySetInnerHTML={{ __html: text + (text.length > 60 && showFullText ? "<br><small><b>Show less ...</b></small>" : "") }}></span>
            }
        </li>
    )
}
export default BenefitItem;