import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getApp } from "../../../store/slices/app.slice";

import lensCorrect from "../../../assets/svg/lens-correct.svg"
import lensProtect from "../../../assets/svg/lens-protect.svg"
import lensEnhance from "../../../assets/svg/lens-enhance.svg"


import chevronDown from '../../../assets/svg/chevron-down.svg';

import Modal from "../../../components/Modal";
import SeeAnswerModal from "./SeeAnswerModal";
import lensCorrect_white from '../../../assets/svg/lensCorrect_white.svg';
import lensProtect_white from '../../../assets/svg/lensProtect_white.svg';
import lensEnhance_white from '../../../assets/svg/lensEnhance_white.svg';

import useTranslation from "../../../utils/useTranslation";
import BenefitItem from "./BenefitItem";

interface Props {
    item: any,
    index: number,
    benefitCardHeading?: string[],
    headingIndex: number,
    isRomi: boolean,
}

const BenefitCard: React.FC<Props> = ({ item, index, benefitCardHeading, headingIndex, isRomi }) => {
    const { theme } = useSelector(getApp);
    const [showAnswer, setShowAnswer] = useState(false)
    const [activeItem, setActiveItem] = useState(-1);
    const { t } = useTranslation(useSelector(getApp));

    const heading: any = benefitCardHeading && benefitCardHeading[headingIndex];

    // const setLensesTheme = () => {
    //     switch (theme) {
    //         case APP_CONSTANT.GENERAL:
    //             return lenses_general
    //         case APP_CONSTANT.RAYBAN:
    //             return lenses_rayban
    //         case APP_CONSTANT.OAKLEY:
    //             return lenses_oakley
    //     }
    // }

    const lensIcons = [lensCorrect, lensProtect, lensEnhance]
    const lensIcons_white = [lensCorrect_white, lensProtect_white, lensEnhance_white]

    if (index === 1) {
        if (item.name !== 'NO RECO') {
            //
        }
    }

    return (
        <div className="main-pair-card">
            <div className="reco-container">
                <h4 className={`pair-name pair-title-${theme}`}>
                    {/* <img src={setLensesTheme()} alt="lenses" /> */}
                    <img src={lensIcons[headingIndex]} alt="lenses" />
                    {t(heading)}
                </h4>
                {
                    item?.display?.[theme]?.name && <h3 className={`product-pair-name-${theme}`}>{isRomi && item?.display?.[theme]?.name == 'product_cz_sapphire_general' ? t('product_cz_sapphire_rayban') : t(item?.display?.[theme]?.name)}</h3>
                }
                <ul className={`reset-list product-recommendation-list reset-list-text${theme}`}>
                    {
                        item?.benefits && item?.benefits[theme]?.map((b: string, i: number) => {

                            return (
                                <BenefitItem
                                    text={t(b)}
                                    i={i}
                                    theme={theme}
                                    handleActiveItem={(i: number) => setActiveItem(i)}
                                    activeItem={activeItem}
                                />
                            )
                        })
                    }
                </ul>
            </div>

            <p className={`see-answer bullet-point-${theme}`} onClick={() => setShowAnswer(true)}>
                {t('benefit_card_see_answer')}
                <img src={chevronDown} alt="chevron down" />
            </p>
           
            <Modal show={showAnswer} bgOverlay={true}>
                <SeeAnswerModal
                    closeModal={() => setShowAnswer(false)}
                    headingCategory={benefitCardHeading && t(benefitCardHeading[headingIndex])}
                    headingIcon={lensIcons_white[headingIndex]}
                    productName={t(item?.display?.[theme]?.name)}
                    headingIndex={headingIndex}
                />
            </Modal>
        </div>
    );
};

export default BenefitCard;
