const svGAOffers = {
    "1_1": [
        {
            tag: "card_badge_budget",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "3_4": [
        {
            tag: "card_badge_standard",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "4_4": [
        {
            tag: "card_badge_standard",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "2_1": [
        {
            tag: "card_badge_budget",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "1_2": [        
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "3_5": [
        {
            tag: "card_badge_standard",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "4_5": [
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_budget",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "2_2": [
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "1_3": [
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_easy_pro"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "2_3": [
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_easy_pro"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "3_2": [
        {
            tag: "card_badge_standard",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
};

const svRB_OKLAY_Offers = {
    "1_1": [
        {
            tag: "card_badge_budget",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "3_4": [
        {
            tag: "card_badge_standard",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "4_4": [
        {
            tag: "card_badge_standard",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        }
    ],
    "2_1": [
        {
            tag: "card_badge_budget",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "1_2": [
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "3_5": [
        {
            tag: "card_badge_standard",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "4_5": [
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_budget",
            title: "product_standard",
            benefits: [
                "product_standard",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "2_2": [
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "1_3": [
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_easy_pro"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_boost",
            benefits: [
                "product_eyezen_boost",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "2_3": [
        {
            tag: "card_badge_standard",
            title: "product_sv_360",
            benefits: [
                "product_sv_360",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_easy_pro"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_eyezen_start",
            benefits: [
                "product_eyezen_start",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
};

const palGA = {
    "6_4": [
        {
            tag: "card_badge_budget",
            title: "product_adaptive_core",
            benefits: [
                "product_adaptive_core",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        },        
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_romi",
            benefits: [
                "product_romi",
                "product_any",
                "product_par"
            ]
        }
    ],
    "7_4": [
        {
            tag: "card_badge_budget",
            title: "product_adaptive_core",
            benefits: [
                "product_adaptive_core",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        },        
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_romi",
            benefits: [
                "product_romi",
                "product_any",
                "product_par"
            ]
        }
    ],
    "1_1": [
        {
            tag: "card_badge_standard",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_romi",
            benefits: [
                "product_romi",
                "product_any",
                "product_par"
            ]
        }
    ],
    "2_1": [
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "1_2": [
        {
            tag: "card_badge_standard",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_romi",
            benefits: [
                "product_romi",
                "product_any",
                "product_par"
            ]
        }
    ],
    "2_2": [
        {
            tag: "card_badge_standard",
            title: "product_adaptive_core",
            benefits: [
                "product_adaptive_core",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "1_3": [
        {
            tag: "card_badge_standard",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_romi",
            benefits: [
                "product_romi",
                "product_any",
                "product_par"
            ]
        }
    ],
    "2_3": [
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "3_4": [
        {
            tag: "card_badge_standard",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_adaptive_core",
            benefits: [
                "product_adaptive_core",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "5_4": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_adaptive_core",
            benefits: [
                "product_adaptive_core",
                "product_any",
                "product_par"
            ]
        }
    ],
    "4_4": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        }
    ],
    "3_5": [
        {
            tag: "card_badge_standard",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_adaptive_core",
            benefits: [
                "product_adaptive_core",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "5_5": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_adaptive_core",
            benefits: [
                "product_adaptive_core",
                "product_any",
                "product_sar"
            ]
        }
    ],
    "4_5": [
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_budget",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_adaptive_core",
            benefits: [
                "product_adaptive_core",
                "product_any",
                "product_sar"
            ]
        }
    ],
};

const palRBA = {
    "1_1": [
        {
            tag: "card_badge_budget",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "2_1": [
        {
            tag: "card_badge_standard",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "1_2": [
        {
            tag: "card_badge_budget",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "2_2": [
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "1_3": [
        {
            tag: "card_badge_budget",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "2_3": [
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "3_4": [
        {
            tag: "card_badge_standard",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "5_4": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        }
    ],
    "4_4": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_par"
            ]
        }
    ],
    "3_5": [
        {
            tag: "card_badge_standard",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_varilux_comfort",
            benefits: [
                "product_varilux_comfort",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "5_5": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_sar"
            ]
        }
    ],
    "4_5": [
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_budget",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_fbs04a",
            benefits: [
                "product_fbs04a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_sar"
            ]
        }
    ],
};

const palOAKLEY = {
    "1_1": [
        {
            tag: "card_badge_budget",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "2_1": [
        {
            tag: "card_badge_budget",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "1_2": [
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "2_2": [
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "1_3": [
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_easy_pro"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "2_3": [
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_easy_pro"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_rock"
            ]
        }
    ],
    "3_4": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "5_4": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "4_4": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_par"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_par"
            ]
        }
    ],
    "3_5": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "5_5": [
        {
            tag: "card_badge_standard",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_perfect_match",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
    "4_5": [
        {
            tag: "card_badge_perfect_match",
            altTag: "card_badge_budget",
            title: "product_snl6",
            benefits: [
                "product_snl6",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_standard",
            title: "product_snl7a",
            benefits: [
                "product_snl7a",
                "product_any",
                "product_sar"
            ]
        },
        {
            tag: "card_badge_premium",
            title: "product_standard_oakley",
            benefits: [
                "product_standard_oakley",
                "product_any",
                "product_cz_sapphire"
            ]
        }
    ],
}
const offerRecommendation = {
    "SV": {
        "general": svGAOffers,
        "oakley": svRB_OKLAY_Offers,
        "rayban": svRB_OKLAY_Offers,
    },
    "PAL": {
        "general": palGA,
        "rayban": palRBA,
        "oakley": palOAKLEY
    }
}

export default offerRecommendation;