import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSlice, { getApp } from "../../store/slices/app.slice";

// COMPONENTS
import MainPairBanner from "./components/MainPairBanner";
import AdditionalPairCard from "./components/AdditionalPairCard";
import BenefitCards from "./components/BenefitCard";
import PackageOfferCard from "./components/PackageOfferCard";
import APP_CONSTANT, { APP_ANALYTICS, COMMERCIAL_TITLE_NAME } from "../../constants/index.constant";
import correctRecommendation from "../../constants/correct-recommendation.constant";
import protectRecommendation from "../../constants/protect-recommendation.constant";
import enhanceRecommendation from "../../constants/enhance-recommendation.constant";
import additionalPairRecommendation from "../../constants/additional-pair-recommendation.constant";
import offerRecommendation from "../../constants/offer-recommendation.constant";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../utils/useTranslation";

const Recommendation = () => {
    const { theme, visionType, earnedPoints, homeView } = useSelector(getApp);
    const [noOfAddPairsReco, setNoOfAddPairsReco] = useState<any>({ no: -1, type: 'NONE' });
    const [additionalPairs, setAdditionalPairs] = useState<any>([]);
    const [correctReco, setCorrectReco] = useState<any>({ lensType: APP_CONSTANT.CORRECT, id: 0, name: 'NO RECO', branded: false });
    const [protectReco, setProtectReco] = useState<any>({ lensType: APP_CONSTANT.PROTECT, id: 0, name: 'NO RECO' });
    const [enhanceReco, setEnhanceReco] = useState<any>({ lensType: APP_CONSTANT.ENHANCE, id: 0, name: 'NO RECO' });
    const [packageOfferItems, setPackageOfferItems] = useState<any>();
    const [isRomi, setIsRomi] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation(useSelector(getApp));

    useEffect(() => {
        if (homeView === 0) {
            dispatch(appSlice.actions.setBackToInitialState())
            dispatch(appSlice.actions.setHomeView(1))
            navigate("/")
        }
    }, [])

    // {
    //     [
    //         "standard",
    //         "The perfect match",
    //         "Premium"
    //     ]
    // }

    // Middle offer title woube be same as correct product title
    // if in correct inferier is not possible then check on enhance
    // if superior is not possible in correct then echeck in enhance

    useEffect(() => {
        addPairCalculation();
        correctRecoCalculation();
        return () => { }
    }, [earnedPoints]);

    useEffect(() => {
        if (noOfAddPairsReco.no > -1) {
            protectRecoCalculation();
        }
    }, [noOfAddPairsReco])

    useEffect(() => {
        if (correctReco?.id) {
            enhanceRecoCalculation();
        }
    }, [correctReco])

    useEffect(() => {
        if (correctReco?.id && enhanceReco?.id) {
            packageOfferRecoCalculation();
        }
    }, [correctReco, enhanceReco])

    const addPairCalculation = () => {
        /* Add. Pairs Calculation */
        let totalOf1to3: number = 0;
        let pairsData: any = { no: 0, type: 'NONE' };
        for (let i = 1; i <= 3; i++) {
            totalOf1to3 += earnedPoints[i - 1].pt;
        }
        if (visionType === APP_CONSTANT.PAL) {
            //Max 3 add. pairs Reco.
            if (totalOf1to3 > 10 || totalOf1to3 === 10 && earnedPoints[2].pt === 4) {
                pairsData = { no: 3, type: '' }
            } else if (totalOf1to3 >= 7 && totalOf1to3 <= 10) {
                pairsData = { no: 2, type: APP_CONSTANT.HIGH }
            } else if (totalOf1to3 >= 3 && totalOf1to3 <= 6) {
                pairsData = { no: 2, type: APP_CONSTANT.LOW }
            }

        } else {
            // Max 2 add. pairs Reco.
            if (totalOf1to3 > 6) {
                pairsData = { no: 2, type: APP_CONSTANT.HIGH }
            } else if (totalOf1to3 >= 3 && totalOf1to3 <= 6) {
                pairsData = { no: 2, type: APP_CONSTANT.LOW }
            }
        }
        let data = [];
        for (let i = 0; i < pairsData.no - 1; i++) {
            let aPR: any = additionalPairRecommendation;
            let benefit: any;
            let arg = pairsData.no > 2 && i === 0 && visionType === APP_CONSTANT.PAL;
            benefit = aPR[arg ? APP_CONSTANT.INDOOR : APP_CONSTANT.SUN]
            data.push({ ...benefit, index: i, type: pairsData.type })
        }
        setAdditionalPairs(data);
        setNoOfAddPairsReco(pairsData)
        /* Add. pairs calculation*/
    }

    const correctRecoCalculation = () => {
        const cR: any = correctRecommendation;
        let correctData = { lensType: APP_CONSTANT.CORRECT, id: 0, name: 'NO RECO' };
        if (visionType === APP_CONSTANT.PAL) {
            // 9 , 10 , 11 
            // PAL
            // Apply ROMI for GA only
            if (theme === APP_CONSTANT.GENERAL) {
                if ([4].includes(earnedPoints[9 - 1].pt)) {
                    correctData = cR[APP_CONSTANT.PAL][5];
                } else if ([3].includes(earnedPoints[9 - 1].pt)) {
                    if ([4, 3].includes(earnedPoints[10 - 1].pt)) {
                        correctData = cR[APP_CONSTANT.PAL][0];
                    } else {
                        correctData = cR[APP_CONSTANT.PAL][1];
                    }
                } else {
                    if ([4, 3].includes(earnedPoints[11 - 1].pt)) {
                        if ([4].includes(earnedPoints[10 - 1].pt)) {
                            correctData = cR[APP_CONSTANT.PAL][5];
                        } else if ([2, 3].includes(earnedPoints[10 - 1].pt)) {
                            correctData = cR[APP_CONSTANT.PAL][6];
                        } else {
                            correctData = cR[APP_CONSTANT.PAL][3];
                        }
                    } else {
                        correctData = cR[APP_CONSTANT.PAL][4];
                    }
                }
            } else {
                if ([4].includes(earnedPoints[9 - 1].pt)) {
                    if ([4, 3].includes(earnedPoints[10 - 1].pt)) {
                        correctData = cR[APP_CONSTANT.PAL][0];
                    } else {
                        correctData = cR[APP_CONSTANT.PAL][1];
                    }
                } else {
                    if ([4, 3].includes(earnedPoints[11 - 1].pt)) {
                        if ([4, 3].includes(earnedPoints[10 - 1].pt)) {
                            correctData = cR[APP_CONSTANT.PAL][2];
                        } else {
                            correctData = cR[APP_CONSTANT.PAL][3];
                        }
                    } else {
                        correctData = cR[APP_CONSTANT.PAL][4];
                    }
                }
            }
        } else {
            // 9 , 10 , 11 
            // SV
            if (earnedPoints[9 - 1].pt === 4) {
                correctData = cR[APP_CONSTANT.SV][0];
            } else if (earnedPoints[9 - 1].pt === 3) {
                correctData = cR[APP_CONSTANT.SV][1];
            } else if ([4].includes(earnedPoints[10 - 1].pt)) {
                correctData = cR[APP_CONSTANT.SV][1];
            } else if ([4, 3].includes(earnedPoints[11 - 1].pt)) {
                correctData = cR[APP_CONSTANT.SV][2];
            } else {
                correctData = cR[APP_CONSTANT.SV][3];
            }
        }
        setCorrectReco({ ...correctData, lensType: APP_CONSTANT.CORRECT, headingId: 0 })
    }

    const protectRecoCalculation = () => {
        const pR: any = protectRecommendation;
        let protectData = { lensType: APP_CONSTANT.PROTECT, id: 0, name: 'NO RECO' };
        // Protect 6, 7, 8
        if (
            earnedPoints[6 - 1].pt >= 3 &&
            (
                (earnedPoints[7 - 1].pt + earnedPoints[8 - 1].pt) < 3
            )
        ) {
            protectData = pR[0];
        } else if (noOfAddPairsReco.no > 0 && (earnedPoints[7 - 1].pt >= 3 || earnedPoints[8 - 1].pt >= 3)) {
            protectData = pR[1];
        } else if ((noOfAddPairsReco.no === 0 && (earnedPoints[7 - 1].pt >= 3 || earnedPoints[8 - 1].pt >= 3))) {
            protectData = pR[2];
        } else {
            //12
            if (earnedPoints[12 - 1].pt >= 3) {
                protectData = pR[3];
            }
        }
        setProtectReco({ ...protectData, lensType: APP_CONSTANT.PROTECT, headingId: 1 })
    }

    const enhanceRecoCalculation = () => {
        const eRR: any = enhanceRecommendation;
        const vt: any = visionType;
        const eR = eRR[vt];
        let enhanceData = { lensType: APP_CONSTANT.ENHANCE, id: 0, name: 'NO RECO' };
        // 4 ,5 
        const sum = earnedPoints[4 - 1].pt + earnedPoints[5 - 1].pt;
        if (correctReco.branded === true) {
            if (theme === APP_CONSTANT.GENERAL) {
                if (sum > 3) {
                    enhanceData = eR[0];
                } else if (sum > 1) {
                    enhanceData = eR[1];
                } else if (sum > 0) {
                    enhanceData = eR[2];
                }
            } else {
                enhanceData = eR[1];
            }
        } else {
            if (theme === APP_CONSTANT.GENERAL && correctReco.name === "product_sv_360") {
                // w45, 2023 launch
                if (sum > 6) {
                    enhanceData = eR[1];
                } else if (sum >= 2 && sum <= 6) {
                    enhanceData = eR[3];
                } else if (sum > 0) {
                    enhanceData = eR[4];
                }
            } else {
                if (sum > 1) {
                    enhanceData = eR[3];
                } else if (sum > 0) {
                    enhanceData = eR[4];
                }
            }
        }

        setEnhanceReco({ ...enhanceData, lensType: APP_CONSTANT.ENHANCE, headingId: 2 })
    }

    const packageOfferRecoCalculation = () => {
        const id = correctReco?.id + '_' + enhanceReco?.id;
        console.log(theme, visionType, "offer_id", id)
        let data: any = [];
        const oR: any = offerRecommendation;
        const vt: any = visionType;
        data = oR?.[vt]?.[theme]?.[id];
        setIsRomi(data?.some((e: any) => e.title == 'product_romi'));
        setPackageOfferItems(data);
    }

    // Additional rule - when a benefit card is empty (correct, protect, enhance), do not show that empty benefit card
    const checkBenefitPointCards = () => {
        let benefitsArray = [correctReco, protectReco, enhanceReco]

        // filter out benefit card when no reco
        return benefitsArray.filter(benefit => benefit.name.toLowerCase() !== 'no reco')
    }

    const setBenefitCardTitle = () => {
        let isBrandedProduct = correctReco.branded
        let hasBrandedProduct = theme === 'general' || theme === 'rayban'
        let isBranded = hasBrandedProduct && isBrandedProduct

        if (isBranded) {
            return ['lens_catergory_correct', 'lens_category_protect', 'lens_category_enhance']
        } else {
            if (protectReco.name.toLowerCase() === 'transitions') {
                return ['lens_category_lens_design', 'lens_category_photochromic', 'lens_coating_lens_coating']
            } else if (protectReco.name.toLowerCase() === 'blue cut') {
                return ['lens_category_lens_design', 'lens_category_blue_violet_filtering', 'lens_coating_lens_coating']
            } else { // oakley
                return ['lens_category_lens_design', 'lens_category_blue_violet_filtering', 'lens_coating_lens_coating']
            }
        }
    }

    useEffect(() => {
        if (packageOfferItems) {
            applyUTag();
        }
    }, [packageOfferItems])

    const applyUTag = () => {
        const { __uTag }: any = window;
        let prods: any = [];
        let product_category: any = [];

        packageOfferItems && packageOfferItems?.forEach((item: any) => {
            prods.push(COMMERCIAL_TITLE_NAME?.[theme]?.title?.[item.title])
            let tag = t(item?.tag);
            if (item?.altTag) tag += ", " + t(item?.altTag);
            product_category.push(tag)
        })

        __uTag && __uTag({
            "event_name": "page_view",
            "page_section1": "resultpage",
            "page_section2": ``,
            "screen_title": `${theme}:${visionType === APP_CONSTANT.PAL ? APP_ANALYTICS.PAL : APP_ANALYTICS.SV}`,
            "product_id": prods,
            "product_category": product_category,
            "suggested_correction": t(correctReco?.display?.[theme]?.name) ?? "",
            "suggested_protection": t(protectReco?.display?.[theme]?.name) ?? "",
            "suggested_enhancement": t(enhanceReco?.display?.[theme]?.name) ?? "",
        });
    }

    return (
        <div className="recommendation-page">
            {/* <h4 className={`disclaimer-text-${theme}`}>PAGE FOR ASSOCIATE VISIBILITY ONLY</h4> */}
            <section className="main-pair-section">
                <MainPairBanner correctReco={correctReco} />

                <div className="divider"></div>
                <h1 className={`main-recom-title-${theme}`}>
                    {t('reco_page_section_title_main_pair')}
                </h1>

                <div className="main-pair-cards-container">
                    {/* {[correctReco, protectReco, enhanceReco].map((item: any, index: number) => ( */}
                    {checkBenefitPointCards().map((item: any, index: number) => (
                        <BenefitCards isRomi={isRomi} key={index} item={item} index={index} benefitCardHeading={setBenefitCardTitle()} headingIndex={item.headingId} />
                    ))}
                </div>
            </section>
            {
                additionalPairs?.length > 0 &&
                <section className="additional-pair-section">
                    <div className="divider"></div>
                    <h1 className={`main-recom-title-${theme}`}>
                        {t('reco_page_section_title_dedicated_pair')}
                    </h1>

                    <div className="additional-pair-cards-container">
                        {
                            additionalPairs?.map((item: any, index: number) => (
                                <AdditionalPairCard item={item} key={index} index={index} />
                            ))
                        }
                    </div>
                </section>
            }

            {
                packageOfferItems && (
                    <section className="package-offer-section">
                        <div className="divider"></div>
                        <h1 className={`main-recom-title-${theme}`}>
                            {t('reco_page_section_title_lens_options')}
                        </h1>
                        <div className="package-offer-cards-container">
                            {packageOfferItems.map((item: any, index: number) => (
                                <PackageOfferCard key={index} item={item} index={index} protectReco={protectReco} />
                            ))}
                        </div>

                    </section>
                )
            }
            {/* <h4 className={`disclaimer-text-${theme}`}>PAGE FOR ASSOCIATE VISIBILITY ONLY</h4> */}
            {
                correctReco?.name !== 'NO RECO' && (
                    <section className="disclaimer-section">
                        <i>
                            {
                                correctReco?.name.split("_").includes('romi') ?
                                    <p>
                                        <span dangerouslySetInnerHTML={{__html: t('disclaimer_text_adaptive1')}}></span>
                                        <span dangerouslySetInnerHTML={{__html: t('disclaimer_text_adaptive2')}}></span>
                                    </p> : ''
                            }
                        </i>
                    </section>
                )
            }
            {
                protectReco?.name !== 'NO RECO' && (
                    <section className="disclaimer-section">
                        <i>
                            {
                                protectReco?.name.split("_").includes('transitions') ?
                                    <p>
                                        <span>{t('disclaimer_text_transition1')}</span>
                                        <span>{t('disclaimer_text_transition2')}</span>
                                    </p> :
                                    <p>
                                        <span>{t('disclaimer_text_blue_cut')}</span>
                                    </p>
                            }
                        </i>
                    </section>
                )
            }
        </div>
    );
};

export default Recommendation;
