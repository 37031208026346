const APP_CONSTANT = {
    SV: "SV",
    PAL: "PAL",
    RAYBAN: "rayban",
    OAKLEY: "oakley",
    GENERAL: "general",
    HIGH: 'HIGH',
    LOW: 'LOW',
    CORRECT: 'Correct',
    PROTECT: 'Protect',
    ENHANCE: 'Enhance',
    UN_BRANDED: 'UN_BRANDED',
    BRANDED: 'BRANDED',
    SUN: 'SUN',
    INDOOR: 'INDOOR',
    EN: 'EN',
    FR: 'FR',
}

export const APP_ANALYTICS = {
    PAL: "progressive",
    SV: "single",
    RAYBAN: "RBApp",
    OAKLEY: "MadScience",
    GENERAL: "LensSimulator",
    RESTART: "restart",
    RESULT_PAGE: "resultpage",
    BUTTON: "button",
    CUSTOM_CLICK: "custom_click",
    OPEN: "open",
    BACK: "back",
    EDIT_ANSWERS: "editanswers",
    QUESTIONNAIRE_COMPLETED: "questionnairecompleted",
    GO_TO_RECOMMENDATIONS: "gotorecommendations",
    CLICK: "click",
}

export const COMMERCIAL_TITLE_NAME: {
    [key: string]: {
        [key: string]: {
            [key: string]: string
        }
    }
} = {
    "general": {
        "title": {
            "product_sv_360": "product_sv_360_general",
            "product_standard": "product_standard_general",
            "product_eyezen_start": "product_eyezen_start_general",
            "product_eyezen_boost": "product_eyezen_boost_general",
            "product_varilux_comfort": "product_varilux_comfort_general",
            "product_standard_oakley": "product_varilux_physio_general",
            "product_snl7a": "product_snl7a_general",
            "product_fbs04a": "product_fbs04a_general",
            "product_snl6": "product_snl6_general",
            "product_romi": "product_romi_general",
            "product_adaptive_core": "product_adaptive_core_general",
        }
    },
    "rayban": {
        "title": {
            "product_sv_360": "product_sv_360_rayban",
            "product_standard": "product_standard_rayban",
            "product_eyezen_start": "product_eyezen_start_rayban",
            "product_eyezen_boost": "product_eyezen_boost_rayban",
            "product_varilux_comfort": "product_varilux_comfort_rayban",
            "product_standard_oakley": "product_varilux_physio_rayban",
            "product_snl7a": "product_snl7a_rayban",
            "product_fbs04a": "product_fbs04a_rayban",
            "product_snl6": "product_snl6_rayban",
            "product_romi": "product_romi_rayban",
            "product_adaptive_core": "product_adaptive_core_rayban",
        }
    },
    "oakley": {
        "title": {
            "product_sv_360": "product_sv_360_oakley",
            "product_standard": "product_standard_oakley",
            "product_eyezen_start": "product_eyezen_boost_oakley",
            "product_eyezen_boost": "product_eyezen_boost_oakley",
            "product_varilux_comfort": "product_varilux_physio_oakley",
            "product_standard_oakley": "product_varilux_physio_oakley",
            "product_snl7a": "product_snl7a_oakley",
            "product_fbs04a": "product_snl7a_oakley",
            "product_snl6": "product_snl6_oakley",
            "product_romi": "product_romi_oakley",
            "product_adaptive_core": "product_adaptive_core_oakley",
        }
    }
}

export const COMMERCIAL_BENEFIT_NAME: {
    [key: string]: {
        [key: string]: {
            [key: string]: string
        }
    }
} = {
    "SV": {
        "general": {
            "product_sv_360": "product_sv_360_general",
            "product_sar": "product_sar_general",
            "product_par": "product_par_general",
            "product_cz_easy_pro": "product_cz_easy_pro_general",
            "product_cz_rock": "product_cz_rock_general",
            "product_cz_sapphire": "product_cz_sapphire_general",
            "product_eyezen_start": "product_eyezen_start_general",
            "product_eyezen_boost": "product_eyezen_boost_general",
            "product_varilux_comfort": "product_varilux_comfort_general",
            "product_standard_oakley": "product_varilux_physio_general",
            "product_fbs04a": "product_fbs04a_general",
            "product_snl6": "product_snl6_general",
            "product_snl7a": "product_snl7a_general",
            "product_any": "product_any",
            "product_standard": "product_standard_general",
            "product_romi": "product_romi_general",
            "product_adaptive_core": "product_adaptive_core_general",
        },
        "rayban": {
            "product_sv_360": "product_sv_360_rayban",
            "product_sar": "product_sar_rayban",
            "product_par": "product_par_rayban",
            "product_cz_easy_pro": "product_cz_rock_general",
            "product_cz_rock": "product_cz_rock_general",
            "product_cz_sapphire": "product_cz_rock_general",
            "product_eyezen_start": "product_eyezen_start_rayban",
            "product_eyezen_boost": "product_eyezen_boost_rayban",
            "product_varilux_comfort": "product_varilux_comfort_rayban",
            "product_standard_oakley": "product_varilux_physio_rayban",
            "product_fbs04a": "product_fbs04a_rayban",
            "product_snl6": "product_snl6_rayban",
            "product_snl7a": "product_snl7a_rayban",
            "product_any": "product_any",
            "product_standard": "product_standard_rayban",
            "product_romi": "product_romi_rayban",
            "product_adaptive_core": "product_adaptive_core_rayban",
        },
        "oakley": {
            "product_sv_360": "product_sv_360_oakley",
            "product_sar": "product_sar_oakley",
            "product_par": "product_par_oakley",
            "product_cz_easy_pro": "product_sar_oakley",
            "product_cz_rock": "product_par_oakley",
            "product_cz_sapphire": "product_par_oakley",
            "product_eyezen_start": "product_eyezen_boost_oakley",
            "product_eyezen_boost": "product_eyezen_boost_oakley",
            "product_varilux_comfort": "product_varilux_physio_oakley",
            "product_standard_oakley": "product_varilux_physio_oakley",
            "product_fbs04a": "product_snl7a_oakley",
            "product_snl6": "product_snl6_oakley",
            "product_snl7a": "product_snl7a_oakley",
            "product_any": "product_any",
            "product_standard": "product_standard_oakley",
            "product_romi": "product_romi_oakley",
            "product_adaptive_core": "product_adaptive_core_oakley",
        }
    },
    "PAL": {
        "general": {
            "product_sv_360": "product_sv_360_general",
            "product_sar": "product_sar_general",
            "product_par": "product_par_general",
            "product_cz_easy_pro": "product_cz_easy_pro_general",
            "product_cz_rock": "product_cz_rock_general",
            "product_cz_sapphire": "product_cz_sapphire_general",
            "product_eyezen_start": "product_eyezen_start_general",
            "product_eyezen_boost": "product_eyezen_boost_general",
            "product_varilux_comfort": "product_varilux_comfort_general",
            "product_standard_oakley": "product_varilux_physio_general",
            "product_fbs04a": "product_fbs04a_general",
            "product_snl6": "product_snl6_general",
            "product_snl7a": "product_snl7a_general",
            "product_any": "product_any",
            "product_standard": "product_standard_general",
            "product_romi": "product_romi_general",
            "product_adaptive_core": "product_adaptive_core_general",
        },
        "rayban": {
            "product_sv_360": "product_sv_360_rayban",
            "product_sar": "product_sar_rayban",
            "product_par": "product_par_rayban",
            "product_cz_easy_pro": "product_cz_rock_general",
            "product_cz_rock": "product_cz_rock_general",
            "product_cz_sapphire": "product_cz_rock_general",
            "product_eyezen_start": "product_eyezen_start_rayban",
            "product_eyezen_boost": "product_eyezen_boost_rayban",
            "product_varilux_comfort": "product_varilux_comfort_rayban",
            "product_standard_oakley": "product_varilux_physio_rayban",
            "product_fbs04a": "product_fbs04a_rayban",
            "product_snl6": "product_snl6_rayban",
            "product_snl7a": "product_snl7a_rayban",
            "product_any": "product_any",
            "product_standard": "product_standard_rayban",
            "product_romi": "product_romi_rayban",
            "product_adaptive_core": "product_adaptive_core_rayban",
        },
        "oakley": {
            "product_sv_360": "product_sv_360_oakley",
            "product_sar": "product_sar_oakley",
            "product_par": "product_par_oakley",
            "product_cz_easy_pro": "product_sar_oakley",
            "product_cz_rock": "product_par_oakley",
            "product_cz_sapphire": "product_par_oakley",
            "product_eyezen_start": "product_eyezen_boost_oakley",
            "product_eyezen_boost": "product_eyezen_boost_oakley",
            "product_varilux_comfort": "product_varilux_physio_oakley",
            "product_standard_oakley": "product_varilux_physio_oakley",
            "product_fbs04a": "product_snl7a_oakley",
            "product_snl6": "product_snl6_oakley",
            "product_snl7a": "product_snl7a_oakley",
            "product_any": "product_any",
            "product_standard": "product_standard_oakley",
            "product_romi": "product_romi_oakley",
            "product_adaptive_core": "product_adaptive_core_oakley",
        }
    }
}

export default APP_CONSTANT;
