export const TRANSLATIONS_DEFAULT = {
    "EN": {
        "translation": {
            "select_language": "Select Language",
            "personalize_your_lenses": "PERSONALIZE YOUR LENSES",
            "lens_advisor_intro_lense_crafter": "LensCrafters brings the best technologies together to create the perfect lens for your lifestyle. Tell us about your needs so we can help you find a personalized solution for your best vision possible.",
            "lens_advisor_intro_purpose": "The purpose of the questionnaire is to provide you with recommendations based on persnal needs, vision challenges and hobbies. These data are not stored. They are used to give a personalized recommendation.",
            "btn_next": "Next",
            "btn_decline": "Decline",
            "brand_text": "Brand",
            "brand_selection": "SELECT THE BRAND OF THE FRAME THAT YOU HAVE SELECTED IN STORE",
            "vision_type_selection": "SELECT BETWEEN SINGLE VISION AND PROGRESSIVE LENSES",
            "progressive_vision": "Progressive",
            "single_vision": "Single Vision",
            "qstn_category_daily_activities": "Daily Activities",
            "qstn_category_lens_performance": "Lens Performance",
            "qstn_category_light_sensitivity": "Light Sensitivity",
            "qstn_category_vision_habits": "Vision Habits",
            "progress_text_profile": "Profile",
            "btn_back": "Back",
            "question_time_wearing_lenses": "HOW MANY DAYS A WEEK ARE YOU SPENDING YOUR TIME WEARING YOUR LENSES WHILE?",
            "questionnaire_type_duration": "Duration question",
            "qstn_card_outdoor_sport": "OUTDOOR @SPORT",
            "qstn_card_outdoor_leisure": "OUTDOOR @LEISURE",
            "qstn_card_indoor_work": "INDOOR @WORK",
            "question_lenses_need_to_be_cleaned": "My daily routine call for lenses that need to be cleaned often.",
            "questionnaire_type_opinion": "Please give your opinion on the following statements:",
            "qstn_answer_strongly_agree": "Strongly Agree",
            "qstn_answer_agree": "Agree",
            "qstn_answer_disagree": "Disagree",
            "qstn_answer_strongly_disagree": "Strongly Disagree",
            "question_transparency_of_lenses": "In my daily activities the transparency of lenses is very important",
            "question_bothered_daylight_outdoors": "I am often bothered by daylight outdoors.",
            "question_bothered_indoor_lights": "I am often bothered by harsh indoor lights.",
            "question_bothered_sunlight_driving": "I am often bothered by sunlight and surroundings while driving during the day.",
            "question_time_performing_activities": "IN A TYPICAL DAY, HOW MUCH TIME DO YOU SPEND PERFORMING ACTIVITIES:",
            "qstn_card_near_distance": "AT NEAR DISTANCE (smartphone, reading)",
            "qstn_card_intermediate_distance": "AT INTERMEDIATE DISTANCE (laptop, office environment, cooking)",
            "qstn_card_far_distance": "AT FAR DISTANCE (TV, driving, outdoor activities)",
            "qstn_card_eye_strain": "EYE STRAIN SYMPTOMS",
            "qstn_card_multiple_device": "MULTIPLE DIGITAL DEVICE USAGE",
            "qstn_card_high_vision": "THE NEED OF HIGH VISION ACCURACY",
            "qstn_answer_very_frequently": "Very frequently",
            "qstn_answer_frequently": "Frequently",
            "qstn_answer_often": "Often",
            "qstn_answer_sometimes": "Sometimes",
            "question_blue_uv_filteration": "IS IT IMPORTANT TO HAVE UV & BLUE FILTERATION?",
            "summary_card_qstn_done": "Questionnaire Done",
            "summary_card_modify_answer": "Modify Answers",
            "summary_card_or": "OR",
            "summary_card_reco_page": "Recommendation Page",
            "btn_restart": "Restart",
            "reco_page_section_title_main_pair": "MAIN PAIR : PRODUCT RECOMMENDATION",
            "lens_catergory_correct": "Correct",
            "lens_category_protect": "Protect",
            "lens_category_enhance": "Enhance",
            "lens_category_lens_design": "Lens Design",
            "lens_category_photochromic": "Photochromic",
            "lens_coating_lens_coating": "Lens Coating",
            "lens_category_blue_violet_filtering": "Blue Violet Filtering",
            "benefit_card_see_answer": "See the Answers",
            "reco_page_section_title_dedicated_pair": "ADDITIONAL DEDICATED PAIR",
            "pair_card_title_sun_pair": "DEDICATED SUN PAIR",
            "pair_card_title_indoor_pair": "DEDICATED INDOOR PAIR",
            "reco_page_secion_title_lens_option": "Lens Option",
            "card_badge_budget": "Budget",
            "card_badge_standard": "Standard",
            "card_badge_perfect_match": "The perfect match",
            "card_badge_premium": "Premium",
            "disclaimer_text_transition1": "*Transitions lenses filter at least 26% of blue-violet light indoors & at least 86% outdoors. Tests performed on grey lenses with a premium anti-reflective coating. Blue-violet light is between 400 and 455nm (ISO TR 20772:2018)",
            "disclaimer_text_transition2": "**Transitions lenses block 100% UV & filter at least 26% of blue-violet light indoors & at least 86% outdoors. Tests performed on grey lenses with premium anti-reflective coating. Blue-violet light is between 400 and 455nm (ISO TR 20772:2018).",
            "disclaimer_text_blue_cut": "*Blue-violet light is between 400 and 455nm as stated by ISO TR 20772:2018",
            "btn_accept": "Accept",
            "app_name": "Lens Advisor",
            "question_frequently_during_normal_week": "How frequently during your normal week are you facing:",
            "qstn_answer_never": "Never",
            "reco_page_main_pair": "MAIN PAIR",
            "reco_page_rayban_sv_unbranded": "The perfect match between style and sight. The Ray-Ban frame you love smartly designed with original Ray-Ban lenses in your prescription. Plus, your eyewear is certified by the Ray-Ban signature, a symbol of uncompromising quality and authenticity. Made with single vision lenses taking into account your precise vision needs for the best aesthetics and style.",
            "reco_page_rayban_sv_branded": "The perfect match between style and sight. The Ray-Ban frame you love smartly designed with the latest Essilor lenses in your prescription. Plus, your eyewear is certified by the Ray-Ban signature, a symbol of uncompromising quality and authenticity. Made with single vision lenses taking into account your precise vision needs for the best aesthetics and style.",
            "reco_page_rayban_pal_unbranded": "The perfect match between style and sight. The Ray-Ban frame you love smartly designed with original Ray-Ban lenses in your prescription. Plus, your eyewear is certified by the Ray-Ban signature, a symbol of uncompromising quality and authenticity. Made with progressive lenses taking into account your precise vision needs for the best aesthetics and style.",
            "reco_page_rayban_pal_branded": "The perfect match between style and sight. The Ray-Ban frame you love smartly designed with the latest Essilor lenses in your prescription. Plus, your eyewear is certified by the Ray-Ban signature, a symbol of uncompromising quality and authenticity. Made with progressive lenses taking into account your precise vision needs for the best aesthetics and style.",
            "reco_page_general_sv": "The selected lens is based according to your lifestyle, and visual needs. It will assure you a good vision all day long, with the suitable treatments according to your activities.",
            "reco_page_general_pal": "The optimal vision correction solution, progressive lenses offer maximum clarity of vision while ensuring a smooth and seamless transition between far vision, intermediate vision, and near vision. They are compatible with visual corrections and surface treatments.",
            "reco_page_oakley_sv": "The unrivalled vision performance on and off the field. The state-of-the-art Oakley frame you need is purpose-built with your prescription lenses so you don't have to compromise between performance, aesthetic, and vision quality. Etched with the exclusive laser engraving Oakley's 'O'. An eyewear made for your individual needs with single vision lenses providing superior vision and protection in all circumstances.",
            "reco_page_oakley_pal": "The unrivalled vision performance on and off the field. The state-of-the-art Oakley frame you need is purpose-built with your prescription lenses so you don't have to compromise between performance, style, and vision quality. Etched with the exclusive laser engraving Oakley's 'O'. An eyewear made for your individual needs with progressive lenses providing superior vision and protection in all circumstances.",
            "question_in_a_typical_day": "In a typical day, how much time do you spend performing activities:",
            "qstn_card_near_distance_subheading": "At near distance: smartphone, reading",
            "qstn_answer_6h_more": "6H / more",
            "qstn_answer_4h_to_6h": "4H to 6H",
            "qstn_answer_2h_to_4h": "2H to 4H",
            "qstn_answer_below_2h": "Below 2H",
            "qstn_card_intermediate_subheading": "At Intermediate Distance: Laptop, Office Environment, Cooking",
            "qstn_card_far_distance_subheading": "At Far Distance: TV, Driving, Outdoor Activities",
            "reco_page_section_title_lens_options": "LENS OPTIONS",
            "pair_card_text_sun_pair": "The optimal solution to correct your sight for outdoor uses. The sun prescription offer gives you the greatest of sunwear style combined with protection and vision comfort by limiting glare and filtering UV rays.",
            "pair_card_text_indoor_pair": "Comfortable solution for near and intermediate vision allowing you to not wear glasses all day long. Affordable price compared to progressive lenses and higher efficacy compared to readers.",
            "inactivity_modal_title": "Do you want to start a new session?",
            "inactivity_modal_continue": "Continue",
            "inactivity_modal_new_session": "NEW SESSION",
            "badge_recommended": "Recommended",
            "badge_highly_recommended": "Highly Recommended",
            "product_eyezen_boost": "Eyezen Boost",
            "product_eyezen_boost_general": "Eyezen® +",
            "product_eyezen_boost_rayban": "Eyezen® + (RBE)",
            "product_eyezen_boost_oakley": "SV Top / OTD Elite",
            "product_eyezen_start": "Eyezen Start",
            "product_eyezen_start_general": "Eyezen® Start™",
            "product_eyezen_start_rayban": "Eyezen® Start™ (RBE)",
            "product_eyezen_start_oakley": "SV Top / OTD Elite",
            "product_sv_360": "SV 360",
            "product_sv_360_general": "Digital / SV360",
            "product_sv_360_rayban": "Ray-Ban SV Equalized",
            "product_sv_360_oakley": "SV Premium / OTD Advance Plus",
            "product_standard": "SPH/ASPH",
            "product_standard_general": "Conventional",
            "product_standard_rayban": "Ray-Ban SV Tuned",
            "product_standard_oakley": "SV Advanced / OTD Advance",
            "product_varilux_physio": "Varilux Physio",
            "product_varilux_physio_general": "Varilux® Physio® W3+ Eyecode",
            "product_varilux_physio_rayban": "Varilux® Physio® W3+",
            "product_varilux_physio_oakley": "PAL Top / OTD Elite",
            "product_varilux_comfort": "Varilux Comfort",
            "product_varilux_comfort_general": "Varilux® Comfort Max Eyecode",
            "product_varilux_comfort_rayban": "Varilux® Comfort Max",
            "product_varilux_comfort_oakley": "PAL Top / OTD Elite",
            "product_snl7a": "SNL7A",
            "product_snl7a_general": "PG XVP / Expanded view progressive",
            "product_snl7a_rayban": "Ray-Ban PAL Amplified",
            "product_snl7a_oakley": "PAL Premium / OTD Advance Plus",
            "product_snl6": "SNL6",
            "product_snl6_general": "PG AVP / Advanced view progressive",
            "product_snl6_rayban": "Ray-Ban PAL Tuned",
            "product_snl6_oakley": "PAL Advanced / OTD Advance",
            "product_fbs04a": "FBS04A fit",
            "product_fbs04a_general": "PG EVP / Enhanced view progressive",
            "product_fbs04a_rayban": "Ray-Ban PAL Equalized",
            "product_fbs04a_oakley": "PAL Advanced / OTD Advance",
            "product_any": "Any",
            "product_transitions_gen8": "Transitions",
            "product_transitions_gen8_general": "Transitions®",
            "product_transitions_gen8_rayban": "Transitions®",
            "product_transitions_gen8_oakley": "Transitions®",
            "product_transitions_xtractive": "Transitions",
            "product_transitions_xtractive_general": "Transitions®",
            "product_transitions_xtractive_rayban": "Transitions®",
            "product_transitions_xtractive_oakley": "Transitions®",
            "product_transitions_xtractive_polarized": "Transitions",
            "product_transitions_xtractive_polarized_general": "Transitions®",
            "product_transitions_xtractive_polarized_rayban": "Transitions®",
            "product_transitions_xtractive_polarized_oakley": "Transitions®",
            "product_blue_cut": "BLUE CUT",
            "product_blue_cut_general": "Blue IQ",
            "product_blue_cut_rayban": "Blue Light Ease",
            "product_blue_cut_oakley": "Oakley Blue Ready",
            "product_cz_sapphire": "CZ SAPPHIRE HR",
            "product_cz_sapphire_general": "Crizal Sapphire HR",
            "product_cz_sapphire_rayban": "Crizal Rock",
            "product_cz_sapphire_oakley": "Oakley Stealth ™Pro",
            "product_cz_rock": "CZ ROCK",
            "product_cz_rock_general": "Crizal Rock",
            "product_cz_rock_rayban": "Crizal Rock",
            "product_cz_rock_oakley": "Oakley Stealth ™Pro",
            "product_cz_easy_pro": "CZ EASY PRO",
            "product_cz_easy_pro_general": "Crizal Easy PRO",
            "product_cz_easy_pro_rayban": "Crizal Rock",
            "product_cz_easy_pro_oakley": "Oakley Standard A",
            "product_par": "MC6 Strong",
            "product_par_general": "Premium AR",
            "product_par_rayban": "AR Optimized",
            "product_par_oakley": "Oakley Stealth ™Pro",
            "product_sar": "MC5",
            "product_sar_general": "Standard AR",
            "product_sar_rayban": "AR Classic",
            "product_sar_oakley": "Oakley Standard A",
            "benefit_better_readability": "Better readability of small characters",
            "benefit_visual_fatigue": "Visual fatigue reduction",
            "benefit_improved_contrast": "Improved contrast",
            "benefit_blue_violet_light": "Blue-violet light filtering*",
            "benefit_great_clarity": "Great clarity of vision",
            "benefit_accomodative_support": "Accomodative support for easier focus",
            "benefit_better_visual_acuity": "Better visual acuity",
            "benefit_relaxed_eyes": "Relaxed eyes day after day",
            "benefit_great_vision_with_edge": "Great vision with edge to edge clarity",
            "benefit_etter_contrast": "Better contrast",
            "benefit_great_vision_correction": "Great vision correction solution",
            "benefit_high_vision_resolution": "High vision Resolution",
            "benefit_optimized_sharpness": "Optimized sharpness",
            "benefit_accomodative_support_in_near": "Accomodative support in near vision",
            "benefit_good_clearity": "Good clarity of vision",
            "benefit_good_value": "Good value for money",
            "benefit_clear_vision_correction": "Clear vision correction solution",
            "benefit_seamless_transitions": "Seamless transitions from near to far and from far to near",
            "benefit_sharp_vision_in_all": "Sharp Vision in all light conditions",
            "benefit_effortless_vision_for": "Effortless vision for active life",
            "benefit_wide_reading": "Wide reading area",
            "benefit_smooth_transitions_from": "Smooth transitions from far to near",
            "benefit_designed_for_best_performance": "Designed for best performance in all activites",
            "benefit_customized_on_behavior": "Customized on behavior",
            "benefit_customized_on_wearing": "Customized on wearing parameters",
            "benefit_intermediate_vision": "Intermediate vision Enhancer",
            "benefit_sharp_vision_without_struggle": "Sharp vision without the struggle to find the right posture or gaze direction",
            "benefit_all_day_long_visual_comfort_as": "All-Day-long visual comfort as the safe choice and good value for money",
            "benefit_you_will_get_used_to": "You'll get used to these progressive lenses without even thinking about it",
            "benefit_all_day_long_visual_comfort": "All day long vision comfort",
            "benefit_improved_postural_flexi": "Improved postural flexibility",
            "benefit_easy_adaptation": "Easy adaptation",
            "benefit_superior_vision_at": "Superior vision at all distances",
            "benefit_personalized_for_right": "Personalized for right/left handed",
            "benefit_great_vision_at_all_dist": "Great Vision at all distances",
            "benefit_high_visual_sharpness": "High visual sharpness",
            "benefit_good_balance_of_vision": "Good balance of vision at all distances",
            "benefit_balance_from_far_to_near": "Balance from far to near vision",
            "benefit_good_vision_at_all_dist": "Good vision at all distances",
            "benefit_contrast_and_sharp_vision": "Contrast and sharp vision",
            "benefit_wide_fields_of_vision": "Wide fields of vision",
            "benefit_protection_against_uv": "Protection against UV",
            "benefit_filtering_blue_violet": "*Filtering blue-violet light",
            "benefit_blue_violet_light_filt": "Blue-violet light filtration*",
            "benefit_always_on_protection": "Always-on protection**",
            "benefit_seamlessly_adapt_to_chang": "Seamlessly adapt to changing light situations",
            "benefit_available_in_wide_range_color": "Available in a wide range of colors",
            "benefit_uv_protection": "UV Protection",
            "benefit_highest_transparency_cz": "Highest transparency in Crizal range",
            "benefit_easy_to_clean": "Easy to clean",
            "benefit_dust_repellent": "Dust repellent",
            "benefit_durable_resistance": "Durable resistance",
            "benefit_uv_backside_protection": "UV backside protection",
            "benefit_high_transparency_sharpness": "High transparency and sharpness",
            "benefit_scratch_resistance": "Scratch resistance",
            "benefit_reduced_reflection": "Reduced reflection",
            "benefit_scratch_resistant": "Scratch resistant",
            "benefit_accomodative_support_for_easier": "Accomodative support for easier focus",
            "product_romi_general": "LensCrafters® Adaptive™ Progressive Lenses",
            "benefit_effortless_focus": "<b>Effortless focus</b> when the wearer shifts it's gaze, even in dynamic settings<sup>1</sup>",
            "benefit_clear_vision": "<b>Clear vision</b> at all distances<sup>1</sup>",
            "benefit_tailored_unique": "<b>Tailored</b> to the wearer’s unique <b>posture & near vision</b> requirements",
            "benefit_approved_for_smartphone": "Approved for <b>smartphone & computer</b> usage<sup>2</sup>",
            "disclaimer_text_adaptive1": "<sup>1</sup>LensCrafters® Adaptive™ Progressive Lenses in-Life Consumer Study - 3<sup>rd</sup> Independent party - US - 2024 (n=54)​",
            "disclaimer_text_adaptive2": "<sup>2</sup>92% of wearers expressed seeing clearly when looking at smartphone and computer with Adaptive™ Progressive Lenses, In-Life Consumer Study - 3<sup>rd</sup> Independent party - US - 2024 (n=54)",
            "product_adaptive_core_general": "LensCrafters® Adaptive™ Core Progressive Lenses",
            "benefit_designed_to_provide": "Designed to provide the right power in front of each eye to help support binocular vision and minimize wearer’s effort to focus their gaze including while in movement",
            "benefit_designed_to_maintaine": "Designed to maintain Vision field width for the most common prescriptions* to help provide Clear vision at all distances",
            "benefit_customized_using_pos": "Customized using Position of wear measurements",
            "benefit_designed_for_smartphone": "Designed for smartphone & computer usage",
        }
    },
    "FR": {
        "translation": {
            "select_language": "Sélectionnez votre langue",
            "personalize_your_lenses": "PERSONNALISEZ VOS VERRES",
            "lens_advisor_intro_lense_crafter": "LensCrafters rassemble les meilleures technologies pour créer les lunettes parfait pour votre style de vie. Parlez-nous de vos besoins afin que nous puissions vous aider à trouver une solution personnalisée pour votre meilleure vision.",
            "lens_advisor_intro_purpose": "Le but du questionnaire est de vous fournir des recommandations basées sur les besoins individuels, les besoins visuels et les passe-temps. Ces données ne sont pas stockées. Elles sont utilisées pour donner une recommandation personnalisée.",
            "btn_next": "Suite",
            "btn_decline": "Refuser",
            "brand_text": "Marque",
            "brand_selection": "SÉLECTIONNEZ LA MARQUE DE MONTURE QUE VOUS AVEZ SÉLECTIONNÉ EN MAGASIN",
            "vision_type_selection": "CHOISIR ENTRE LES VERRES SIMPLE FOYERS  ET LES VERRES PROGRESSIFS",
            "progressive_vision": "PROGRESSIFS",
            "single_vision": "SIMPLE FOYERS",
            "qstn_category_daily_activities": "Activités quotidiennes",
            "qstn_category_lens_performance": "Perforance des verres",
            "qstn_category_light_sensitivity": "Sensibilité à la lumière ",
            "qstn_category_vision_habits": "Habitudes visuelles",
            "progress_text_profile": "Profile",
            "btn_back": "Retour",
            "question_time_wearing_lenses": "COMBIEN DE JOURS PAR SEMAINE PORTER-VOUS VOS VERRES ?",
            "questionnaire_type_duration": "Question sur la durée",
            "qstn_card_outdoor_sport": "Sport en extérieur",
            "qstn_card_outdoor_leisure": "Loisir en extérieur",
            "qstn_card_indoor_work": "Travail en intérier",
            "question_lenses_need_to_be_cleaned": "MA ROUTINE QUOTIDIENNE POUR DES VERRES QUI DOIVENT ÊTRE NETTOYÉS SOUVENT.",
            "questionnaire_type_opinion": "Veuillez donner votre avis sur les déclarations suivantes :",
            "qstn_answer_strongly_agree": "Complétement d'accord",
            "qstn_answer_agree": "D'accord",
            "qstn_answer_disagree": "Pas d’accord",
            "qstn_answer_strongly_disagree": "Complétement pas d'accord",
            "question_transparency_of_lenses": "DANS MES ACTIVITÉS QUOTIDIENNES, LA TRANSPARENCE DES VERRES EST TRÈS IMPORTANTE",
            "question_bothered_daylight_outdoors": "JE SUIS SOUVENT DÉRANGÉ PAR LA LUMIÈRE DU JOUR À L’EXTÉRIEUR.",
            "question_bothered_indoor_lights": "JE SUIS SOUVENT DÉRANGÉ PAR LES LUMIÈRES INTÉRIEURES FORTES.",
            "question_bothered_sunlight_driving": "JE SUIS SOUVENT DÉRANGÉ PAR LA LUMIÈRE DU SOLEIL ET LES REFLECTIONS EN CONDUISANT PENDANT LA JOURNÉE.",
            "question_time_performing_activities": "AU COURS D’UNE JOURNÉE TYPIQUE, COMBIEN DE TEMPS PASSEZ-VOUS À EFFECTUER LES ACTIVITÉS :",
            "qstn_card_near_distance": "À PROXIMITÉ (smartphone, lecture)",
            "qstn_card_intermediate_distance": "À DISTANCE INTERMÉDIAIRE (ordinateur portable, environnement de bureau, cuisine)",
            "qstn_card_far_distance": "À DISTANCE (TÉLÉVISION, conduite, activités de plein air)",
            "qstn_card_eye_strain": "SYMPTÔMES DE FATIGUE OCULAIRE",
            "qstn_card_multiple_device": "UTILISATION DE PLUSIEURS APPAREILS NUMÉRIQUES",
            "qstn_card_high_vision": "LE BESOIN D’UNE HAUTE PRÉCISION DE VISION",
            "qstn_answer_very_frequently": "Trés fréquement",
            "qstn_answer_frequently": "Fréquement",
            "qstn_answer_often": "Souvent",
            "qstn_answer_sometimes": "Parfois",
            "question_blue_uv_filteration": "EST-IL IMPORTANT POUR VOUS D’AVOIR UNE FILTRATION DES LUMIERES UV ET BLEUE ?",
            "summary_card_qstn_done": "Questionnaire terminé",
            "summary_card_modify_answer": "Modifier les réponses",
            "summary_card_or": "Ou",
            "summary_card_reco_page": "Page de Recommendation",
            "btn_restart": "Redémarrer",
            "reco_page_section_title_main_pair": "PAIRE PRINCIPALE : RECOMMANDATION DE PRODUIT",
            "lens_catergory_correct": "Corriger",
            "lens_category_protect": "Protéger",
            "lens_category_enhance": "Améliorer",
            "lens_category_lens_design": "Design optique",
            "lens_category_photochromic": "Photochromique",
            "lens_coating_lens_coating": "Traitement du Verre",
            "lens_category_blue_violet_filtering": "Filtration de la lumière bleu-violet",
            "benefit_card_see_answer": "Voir les réponses",
            "reco_page_section_title_dedicated_pair": "PAIRE SUPPLÉMENTAIRE DÉDIÉE",
            "pair_card_title_sun_pair": "PAIRE SOLAIRE DÉDIÉE",
            "pair_card_title_indoor_pair": "PAIRE D’INTÉRIEUR DÉDIÉE",
            "reco_page_secion_title_lens_option": "OPTIONS DE VERRES",
            "card_badge_budget": "Budget",
            "card_badge_standard": "Standard",
            "card_badge_perfect_match": "La Meilleure Solution",
            "card_badge_premium": "Haut de gamme",
            "disclaimer_text_transition1": "*Les verres Transitions filtrent au moins 26% de la lumière bleu-violet à l’intérieur et au moins 86% à l’extérieur. Tests effectués sur des verres gris avec un revêtement antireflet haut de gamme. La lumière bleu-violet se situe entre 400 et 455 nm (ISO TR 20772 :2018)",
            "disclaimer_text_transition2": "**Les verres Transitions bloquent 100% UV et filtrent au moins 26% de lumière bleu-violet à l’intérieur et au moins 86% à l’extérieur. Tests effectués sur des lentilles grises avec un revêtement antireflet haut de gamme. La lumière bleu-violet se situe entre 400 et 455 nm (ISO TR 20772 :2018).",
            "disclaimer_text_blue_cut": "*La lumière bleu-violet est comprise entre 400 et 455 nm comme indiqué par l’ISO TR 20772 :2018",
            "btn_accept": "Accepter",
            "app_name": "Lens Advisor",
            "question_frequently_during_normal_week": "À quelle fréquence, au cours de votre semaine normale, êtes-vous confronté(e):",
            "qstn_answer_never": "Jamais",
            "reco_page_main_pair": "Paire principale",
            "reco_page_rayban_sv_unbranded": "Le mariage parfait entre le style et la vue. Le cadre Ray-Ban que vous aimez intelligemment conçu avec des verres Ray-Ban originaux  à votre prescription. De plus, vos lunettes sont certifiées par la signature Ray-Ban, un symbole de qualité et d’authenticité sans compromis. Fabriqué avec des verres simple foyers en tenant compte de vos besoins de vision précis pour la meilleure esthétique et le meilleur style.",
            "reco_page_rayban_sv_branded": "Le mariage parfait entre le style et la vue. Le cadre Ray-Ban que vous aimez intelligemment conçu avec des verres Essilor à votre prescription. De plus, vos lunettes sont certifiées par la signature Ray-Ban, un symbole de qualité et d’authenticité sans compromis. Fabriqué avec des verres simple foyers en tenant compte de vos besoins de vision précis pour la meilleure esthétique et le meilleur style.",
            "reco_page_rayban_pal_unbranded": "Le mariage parfait entre le style et la vue.Le cadre Ray-Ban que vous aimez intelligemment conçu avec des verres Ray-Ban originaux  à votre prescription. De plus, vos lunettes sont certifiées par la signature Ray-Ban, un symbole de qualité et d’authenticité sans compromis. Fabriqué avec des verres progressifs en tenant compte de vos besoins de vision précis pour la meilleure esthétique et le meilleur style.",
            "reco_page_rayban_pal_branded": "Le mariage parfait entre le style et la vue.Le cadre Ray-Ban que vous aimez intelligemment conçu avec des verres Essilor à votre prescription. De plus, vos lunettes sont certifiées par la signature Ray-Ban, un symbole de qualité et d’authenticité sans compromis. Fabriqué avec des verres progressifs en tenant compte de vos besoins de vision précis pour la meilleure esthétique et le meilleur style.",
            "reco_page_general_sv": "Le verre sélectionné est basé sur votre style de vie et vos besoins visuels. Il vous assurera une bonne vision toute la journée, avec les traitements appropriés en fonction de vos activités.",
            "reco_page_general_pal": "La solution de correction de vision optimale, les verres progressifs offrent une clarté maximale de la vision tout en assurant une transition douce et transparente entre la vision de loin, la vision intermédiaire et la vision de près. Ils sont compatibles avec les corrections visuelles et les traitements de surface.",
            "reco_page_oakley_sv": "La performance de vision inégalée sur et en dehors du terrain. Le cadre Oakley à la pointe de la technologie dont vous avez besoin est spécialement conçu avec vos lentilles de prescription afin que vous n’ayez pas à faire de compromis entre la performance, l’esthétique et la qualité de la vision. Gravé avec la gravure au laser exclusive 'O' d’Oakley. Une lunette faite pour vos besoins individuels avec des lentilles de vision unique offrant une vision et une protection supérieures en toutes circonstances.",
            "reco_page_oakley_pal": "La performance de vision inégalée sur et en dehors du terrain. Le cadre Oakley à la pointe de la technologie dont vous avez besoin est spécialement conçu avec vos lentilles de prescription afin que vous n’ayez pas à faire de compromis entre la performance, l’esthétique et la qualité de la vision. Gravé avec la gravure au laser exclusive 'O' d’Oakley. Une lunette faite pour vos besoins individuels avec des lentilles de vision unique offrant une vision et une protection supérieures en toutes circonstances.",
            "question_in_a_typical_day": "Au cours d’une journée typique, combien de temps passez-vous à effectuer des activités.",
            "qstn_card_near_distance_subheading": "À distance proche smartphone, lecture",
            "qstn_answer_6h_more": "6H / plus",
            "qstn_answer_4h_to_6h": "4H à 6H",
            "qstn_answer_2h_to_4h": "2H à 4H",
            "qstn_answer_below_2h": "Moins de 2H",
            "qstn_card_intermediate_subheading": "À distance intermédiaire Ordinateur portable, Environnement de bureau, Cuisine",
            "qstn_card_far_distance_subheading": "À la télévision à distance, conduite, activités de plein air",
            "reco_page_section_title_lens_options": "Les options de verres",
            "pair_card_text_sun_pair": "La solution optimale pour corriger votre vue pour des utilisations en plein air. L’offre de prescription de soleil vous donne le plus grand style combiné avec la protection et le confort de la vision en limitant l’éblouissement et en filtrant les rayons UV.",
            "pair_card_text_indoor_pair": "Solution confortable pour la vision de près et intermédiaire vous permettant de ne pas porter de lunettes toute la journée. Prix abordable par rapport aux verres progressifs et solution  plus efficace que les verres loupes.",
            "inactivity_modal_title": "Voulez vous commencer une nouvelle session?",
            "inactivity_modal_continue": "Suivant",
            "inactivity_modal_new_session": "Nouvelle Session",
            "badge_recommended": "Recommandé",
            "badge_highly_recommended": "Hautement recommandé",
            "product_eyezen_boost": "Eyezen Boost",
            "product_eyezen_boost_general": "Eyezen® +",
            "product_eyezen_boost_rayban": "Eyezen® + (RBE)",
            "product_eyezen_boost_oakley": "SV Top / OTD Elite",
            "product_eyezen_start": "Eyezen Start",
            "product_eyezen_start_general": "Eyezen® Start™",
            "product_eyezen_start_rayban": "Eyezen® Start™ (RBE)",
            "product_eyezen_start_oakley": "SV Top / OTD Elite",
            "product_sv_360": "SV 360",
            "product_sv_360_general": "Digital / SV360",
            "product_sv_360_rayban": "Ray-Ban SV Equalized",
            "product_sv_360_oakley": "SV Premium / OTD Advance Plus",
            "product_standard": "SPH/ASPH",
            "product_standard_general": "Conventional",
            "product_standard_rayban": "Ray-Ban SV Tuned",
            "product_standard_oakley": "SV Advanced / OTD Advance",
            "product_varilux_physio": "Varilux Physio",
            "product_varilux_physio_general": "Varilux® Physio® W3+ Eyecode",
            "product_varilux_physio_rayban": "Varilux® Physio® W3+",
            "product_varilux_physio_oakley": "PAL Top / OTD Elite",
            "product_varilux_comfort": "Varilux Comfort",
            "product_varilux_comfort_general": "Varilux® Comfort Max Eyecode",
            "product_varilux_comfort_rayban": "Varilux® Comfort Max",
            "product_varilux_comfort_oakley": "PAL Top / OTD Elite",
            "product_snl7a": "SNL7A",
            "product_snl7a_general": "PG XVP / Expanded view progressive",
            "product_snl7a_rayban": "Ray-Ban PAL Amplified",
            "product_snl7a_oakley": "PAL Premium / OTD Advance Plus",
            "product_snl6": "SNL6",
            "product_snl6_general": "PG AVP / Advanced view progressive",
            "product_snl6_rayban": "Ray-Ban PAL Tuned",
            "product_snl6_oakley": "PAL Advanced / OTD Advance",
            "product_fbs04a": "FBS04A fit",
            "product_fbs04a_general": "PG EVP / Enhanced view progressive",
            "product_fbs04a_rayban": "Ray-Ban PAL Equalized",
            "product_fbs04a_oakley": "PAL Advanced / OTD Advance",
            "product_any": "Any",
            "product_transitions_gen8": "Transitions",
            "product_transitions_gen8_general": "Transitions®",
            "product_transitions_gen8_rayban": "Transitions®",
            "product_transitions_gen8_oakley": "Transitions®",
            "product_transitions_xtractive": "Transitions",
            "product_transitions_xtractive_general": "Transitions®",
            "product_transitions_xtractive_rayban": "Transitions®",
            "product_transitions_xtractive_oakley": "Transitions®",
            "product_transitions_xtractive_polarized": "Transitions",
            "product_transitions_xtractive_polarized_general": "Transitions®",
            "product_transitions_xtractive_polarized_rayban": "Transitions®",
            "product_transitions_xtractive_polarized_oakley": "Transitions®",
            "product_blue_cut": "BLUE CUT",
            "product_blue_cut_general": "Blue IQ",
            "product_blue_cut_rayban": "Blue Light Ease",
            "product_blue_cut_oakley": "Oakley Blue Ready",
            "product_cz_sapphire": "CZ SAPPHIRE HR",
            "product_cz_sapphire_general": "Crizal Sapphire HR",
            "product_cz_sapphire_rayban": "Crizal Rock",
            "product_cz_sapphire_oakley": "Oakley Stealth ™Pro",
            "product_cz_rock": "CZ ROCK",
            "product_cz_rock_general": "Crizal Rock",
            "product_cz_rock_rayban": "Crizal Rock",
            "product_cz_rock_oakley": "Oakley Stealth ™Pro",
            "product_cz_easy_pro": "CZ EASY PRO",
            "product_cz_easy_pro_general": "Crizal Easy PRO",
            "product_cz_easy_pro_rayban": "Crizal Rock",
            "product_cz_easy_pro_oakley": "Oakley Standard A",
            "product_par": "MC6 Strong",
            "product_par_general": "Premium AR",
            "product_par_rayban": "AR Optimized",
            "product_par_oakley": "Oakley Stealth ™Pro",
            "product_sar": "MC5",
            "product_sar_general": "Standard AR",
            "product_sar_rayban": "AR Classic",
            "product_sar_oakley": "Oakley Standard A",
            "benefit_better_readability": "Meilleure lisibilité des petits caractères",
            "benefit_visual_fatigue": "Réduction de la fatigue visuelle",
            "benefit_improved_contrast": "Amélioration du contraste",
            "benefit_blue_violet_light": "Filtrage de la lumière bleu-violet*",
            "benefit_great_clarity": "Très bonne clarté de vision",
            "benefit_accomodative_support": "Soutien de l'accomodation pour mise au point plus facile",
            "benefit_better_visual_acuity": "Meilleure acuité visuelle",
            "benefit_relaxed_eyes": "Yeux détendus jour après jour",
            "benefit_great_vision_with_edge": "Très bonne vision  avec une clarté bord à bord",
            "benefit_etter_contrast": "Meilleur Contrast",
            "benefit_great_vision_correction": "Très bonne Solution de correction de la vue",
            "benefit_high_vision_resolution": "Vision Haute Resolution",
            "benefit_optimized_sharpness": "Netteté optimisée",
            "benefit_accomodative_support_in_near": "Soutien de l'accomodation en vision de près",
            "benefit_good_clearity": "Bonne clarté de vision",
            "benefit_good_value": "Bon rapport qualité-prix",
            "benefit_clear_vision_correction": "Solution de correction pour vision claire",
            "benefit_seamless_transitions": "Transitions sans accro de près à loin et de loin à près",
            "benefit_sharp_vision_in_all": "Vision nette dans toutes les conditions de lumière",
            "benefit_effortless_vision_for": "Vision sans effort pour une vie active",
            "benefit_wide_reading": "Large zone de lecture",
            "benefit_smooth_transitions_from": "Transitions en douceur de loin à près",
            "benefit_designed_for_best_performance": "Conçu pour de meilleures performances dans toutes les activités",
            "benefit_customized_on_behavior": "Personnalisé sur le comportement",
            "benefit_customized_on_wearing": "Personnalisé avec les paramètres du patient",
            "benefit_intermediate_vision": "Amplificateur de vision intermédiaire",
            "benefit_sharp_vision_without_struggle": "Vision nette sans effort pour trouver la bonne posture ou la bonne direction de regard",
            "benefit_all_day_long_visual_comfort_as": "Le confort visuel toute la journée comme choix sûr et bon rapport qualité-prix",
            "benefit_you_will_get_used_to": "Vous vous habituerez à ces verres progressifs sans même y penser",
            "benefit_all_day_long_visual_comfort": "Confort de vision toute la journée",
            "benefit_improved_postural_flexi": "Amélioration de la flexibilité posturale",
            "benefit_easy_adaptation": "Adaptation facile",
            "benefit_superior_vision_at": "Vision supérieure pour toutes les distances",
            "benefit_personalized_for_right": "Personnalisé pour droitier / gaucher",
            "benefit_great_vision_at_all_dist": "Très bonne vision pour toutes les distances",
            "benefit_high_visual_sharpness": "Grande netteté visuelle",
            "benefit_good_balance_of_vision": "Bon équilibre de la vision à toutes les distances",
            "benefit_balance_from_far_to_near": "Equilibre entre les visions de loin et de près",
            "benefit_good_vision_at_all_dist": "Bonne vision pour toutes les distances",
            "benefit_contrast_and_sharp_vision": "Contraste et vision nette",
            "benefit_wide_fields_of_vision": "Larges champs de vision",
            "benefit_protection_against_uv": "Protection contre les UV",
            "benefit_filtering_blue_violet": "*Filtrage de la lumière bleu-violet",
            "benefit_blue_violet_light_filt": "Filtration de la lumière bleu-violet*",
            "benefit_always_on_protection": "Protection toujours en cours**",
            "benefit_seamlessly_adapt_to_chang": "S’adapte facilement aux situations lumineuses changeantes",
            "benefit_available_in_wide_range_color": "Disponible dans une large gamme de couleurs",
            "benefit_uv_protection": "UV Protection",
            "benefit_highest_transparency_cz": "La plus grande transparence dans la gamme Crizal",
            "benefit_easy_to_clean": "Facile à nettoyer",
            "benefit_dust_repellent": "Répulsif anti-poussière",
            "benefit_durable_resistance": "Résistance durable",
            "benefit_uv_backside_protection": "Protection UV face arrière",
            "benefit_high_transparency_sharpness": "Haute transparence et netteté",
            "benefit_scratch_resistance": "Résistance aux rayures",
            "benefit_reduced_reflection": "Reduction de la réflexion",
            "benefit_scratch_resistant": "Résistance aux rayures",
            "benefit_accomodative_support_for_easier": "Soutien de l'accomodation pour mise au point plus facile",
            "product_romi_general": "LensCrafters® Adaptive™ Progressive Lenses",
            "benefit_effortless_focus": "<b>Effortless focus</b> when the wearer shifts it's gaze, even in dynamic settings<sup>1</sup>",
            "benefit_clear_vision": "<b>Clear vision</b> at all distances<sup>1</sup>",
            "benefit_tailored_unique": "<b>Tailored</b> to the wearer’s unique <b>posture & near vision</b> requirements",
            "benefit_approved_for_smartphone": "Approved for <b>smartphone & computer</b> usage<sup>2</sup>",
            "disclaimer_text_adaptive1": "<sup>1</sup>LensCrafters® Adaptive™ Progressive Lenses in-Life Consumer Study - 3<sup>rd</sup> Independent party - US - 2024 (n=54)​",
            "disclaimer_text_adaptive2": "<sup>2</sup>92% of wearers expressed seeing clearly when looking at smartphone and computer with Adaptive™ Progressive Lenses, In-Life Consumer Study - 3<sup>rd</sup> Independent party - US - 2024 (n=54)",
            "product_adaptive_core_general": "LensCrafters® Adaptive™ Core Progressive Lenses",
            "benefit_designed_to_provide": "Designed to provide the right power in front of each eye to help support binocular vision and minimize wearer’s effort to focus their gaze including while in movement",
            "benefit_designed_to_maintaine": "Designed to maintain Vision field width for the most common prescriptions* to help provide Clear vision at all distances",
            "benefit_customized_using_pos": "Customized using Position of wear measurements",
            "benefit_designed_for_smartphone": "Designed for smartphone & computer usage",
        }
    },
}